<template>
<div class="mainBox" style="padding:20px;height:100%">
    <el-card class="box-card" style="margin-top:20px">
        <div slot="header" class="clearfix">
            <span>公告内容</span>
        </div>
        <div class="text item">
            <el-input type="textarea" :rows="5" resize="none" v-model="notice_content" placeholder="请输入"></el-input>
        </div>
        <el-button type="primary" size="small" style="display: block;width:100%;margin-top:40px" @click="submit">确定</el-button>
    </el-card>
</div>
</template>
<script>
export default {
    data() {
        return {
            notice_content: ''
        }
    },
    mounted() {
        this.readDetail();
    },
    methods: {
        //公告读取详情
        readDetail: function() {
            this.http.post('/admin.systemConfig/notice').then(re => {
                this.notice_content = re.data;
            })
        },
        submit: function() {
            this.http.post('/admin.systemConfig/noticeSave', {
                content: this.notice_content
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '保存成功!'
                });
                this.readDetail();
            })
        }
    },
}
</script>
